import { Alert as MuiAlert, AlertProps, AlertColor } from "@mui/material";
import { SxProps } from "@mui/system";

const sxMap: Record<AlertColor[0], SxProps> = {
  info: {
    backgroundColor: "grey.200",
    color: "text.primary"
  }
};

const Alert = ({ children, severity, ...rest }: AlertProps) => {
  return (
    <MuiAlert
      variant="filled"
      severity={severity}
      sx={severity && sxMap[severity]}
      {...rest}
    >
      {children}
    </MuiAlert>
  );
};

export default Alert;
