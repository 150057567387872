import IconWithText from "@edenred/icon-with-text";
import { MicroCopyContext } from "@edenred/micro-copy";
import { isMobileMediaQuery } from "@edenred/theme";
import { Box, Stack, SxProps, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import {
  CardTypeDeliveryIcon,
  CardTypeIcon,
  isCharity,
  sxSmallIcon
} from "../components/CardType";
import { roundMoney } from "../utils/money";
import Button from "./Button";
import Image from "./Image";
import { Context } from "./Provider";
import RetailerAvatar from "./RetailerAvatar";

type Props = {
  items: ERSTypes.Basket[];
  homePath: string;
  isCompact?: boolean; // @todo Container query would be nice.
};

const stackSx = (isCompact?: boolean): SxProps => ({
  borderColor: "grey.300",
  borderStyle: "solid",
  borderWidth: 0,
  borderTopWidth: isCompact ? 1 : { xs: 1, sm: 0 }
});

const basketItemSx = (isCompact?: boolean): SxProps => ({
  borderColor: "grey.300",
  borderStyle: "solid",
  borderWidth: 0,
  pt: isCompact ? 3 : { xs: 2.5, sm: 0 },
  borderBottomWidth: isCompact ? 1 : { xs: 1, sm: 0 },
  pb: isCompact ? 3 : { xs: 3, sm: 0 },
  ":last-child": {
    borderBottomWidth: isCompact ? 0 : { xs: 1, sm: 0 },
    pb: isCompact ? 0 : { xs: 3, sm: 0 }
  }
});

const avatarSx = (isCompact?: boolean, isAvatarOnly?: boolean): SxProps => ({
  position: isAvatarOnly ? undefined : { sm: "absolute" },
  top: isAvatarOnly ? undefined : { sm: 8 },
  right: isAvatarOnly ? undefined : { sm: 8 },
  height: isCompact ? 36 : 48,
  width: isCompact ? 36 : 48
});

export default function IntegratedBasket({
  items,
  homePath,
  isCompact
}: Props) {
  const { updateBasket } = useContext(Context);
  const getMicroCopy = useContext(MicroCopyContext);

  const isMobile = isMobileMediaQuery();
  const isTooNarrowForImages = useMediaQuery("(max-width:860px)");
  const hideRetailerImages = isCompact || isTooNarrowForImages;

  function handleRemove(id: string, cardTypeID: number) {
    updateBasket(
      items.filter(
        (item) => !(item.retailer.id === id && item.cardType.id === cardTypeID)
      )
    );
  }

  const buttons = (
    retailer: GatsbyTypes.ContentfulRetailer,
    cardType: ERSTypes.CardType
  ) => (
    <Stack direction="row">
      <Button
        href={`${homePath}/rewards/${retailer.slug}/edit/${cardType.id}`}
        variant="text"
      >
        {getMicroCopy("basket.edit")}
      </Button>
      <Button
        onClick={() => handleRemove(retailer.id, cardType.id)}
        variant="text"
      >
        {getMicroCopy("basket.remove")}
      </Button>
    </Stack>
  );

  if (!items.length) {
    return <Typography>{getMicroCopy("basket.is-empty")}</Typography>;
  }

  return (
    <Stack spacing={isCompact ? 0 : { xs: 0, sm: 2 }} sx={stackSx(isCompact)}>
      {items.map(({ retailer, cardType, value }) => (
        <Box
          key={`${retailer.id}-${cardType.id}-${value}`}
          sx={basketItemSx(isCompact)}
        >
          <Stack direction="row" spacing={isCompact ? 2.5 : { xs: 2.5, sm: 6 }}>
            <Box
              sx={{
                position: "relative",
                width: hideRetailerImages ? undefined : { sm: 340 },
                height: hideRetailerImages ? undefined : { sm: 178 }
              }}
            >
              <RetailerAvatar
                title={retailer.title}
                logo={retailer.logo}
                alt={`${retailer.title} logo`}
                sxStyle={avatarSx(isCompact, hideRetailerImages)}
              />
              {!hideRetailerImages && retailer?.images?.[0] && (
                <Box
                  sx={{
                    borderRadius: 1,
                    overflow: "hidden",
                    display: { xs: "none", sm: "block" },
                    "& > img": {
                      width: 340,
                      height: 178,
                      objectFit: "cover",
                      display: "block"
                    }
                  }}
                >
                  <Image data={retailer.images[0]} />
                </Box>
              )}
            </Box>
            <Stack sx={{ flexGrow: 1 }}>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography component="h2" variant="h6">
                  {retailer.title}
                </Typography>
                <Typography component="p" variant="h5">
                  {getMicroCopy("general.currency", {
                    value: roundMoney(value)
                  })}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", alignItems: "start" }}
              >
                <Stack spacing={0.5} sx={{ mt: 0.5 }}>
                  <IconWithText
                    icon={<CardTypeIcon data={cardType} fontSize="small" />}
                  >
                    {cardType.name}
                  </IconWithText>
                  {!isCharity(cardType) && (
                    <IconWithText
                      icon={
                        <CardTypeDeliveryIcon
                          data={cardType}
                          sx={sxSmallIcon}
                          fontSize="small"
                        />
                      }
                    >
                      {getMicroCopy(`card-type.${cardType.id}.postage`)}
                    </IconWithText>
                  )}
                </Stack>
                {!isCompact && !isMobile && buttons(retailer, cardType)}
              </Stack>
            </Stack>
          </Stack>
          {!isCompact && isMobile && buttons(retailer, cardType)}
        </Box>
      ))}
    </Stack>
  );
}
