import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  children: ReactNode;
};

const IconWithText = ({ icon, children }: Props) => (
  <Stack alignItems="center" color="text.secondary" direction="row" spacing={1}>
    {icon}
    <Typography variant="body2">{children}</Typography>
  </Stack>
);

export default IconWithText;
