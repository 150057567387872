import Alert from "@edenred/alert";
import { ButtonProps } from "@edenred/button";
import Container from "@edenred/container";
import { MicroCopyContext } from "@edenred/micro-copy";
import {
  ErrorOutline as ErrorIcon,
  LocalShippingOutlined as DeliveryIcon,
  MailOutline as MailIcon,
  VolunteerActivismOutlined as CharityIcon
} from "@mui/icons-material";
import { AlertTitle, Box, Card, Grid, Stack, Typography } from "@mui/material";
import { graphql, PageProps } from "gatsby";
import { useContext, useMemo } from "react";
import Basket from "../components/Basket";
import Button from "../components/Button";
import { APICardTypes, isCharity } from "../components/CardType";
import { PageWithHeaderAndFooter } from "../components/Page";
import Protected from "../components/Protected";
import { Context } from "../components/Provider";
import { roundMoney, sumValues } from "../utils/money";

// @todo: Is this coming from somewhere?
const DELIVERY_FEE = { title: "Free", value: 0 };

type Props = PageProps<
  Pick<GatsbyTypes.Query, "contentfulClient">,
  ERSTypes.PageContext
>;

export default function BasketPage({ data, pageContext }: Props) {
  const { contentfulClient } = data;
  const { homePath } = pageContext;
  const { balance, basket } = useContext(Context);
  const getMicroCopy = useContext(MicroCopyContext);
  const sumBasket = useMemo(() => basket.reduce(sumValues, 0), [basket]);
  const isCheckoutAvailable = balance === 0;

  const isSendByMail = basket.some(
    ({ cardType }) => cardType.id === APICardTypes.GIFTCARD
  );
  const isSendByEmail = basket.some(
    ({ cardType }) => cardType.id === APICardTypes.EGIFTCARD
  );
  const anyCharity = basket.some(({ cardType }) => isCharity(cardType));

  const basketTitle = getMicroCopy("basket.title", {
    count: basket.length,
    plural: basket.length === 1 ? "" : "s"
  });

  const CheckoutButton = (props: ButtonProps) => (
    <Button
      size="large"
      href={isCheckoutAvailable ? `${homePath}/checkout` : ""}
      disabled={!isCheckoutAvailable}
      title={
        isCheckoutAvailable
          ? undefined
          : getMicroCopy("basket.checkout-disabled-explanation")
      }
      {...props}
    >
      {getMicroCopy("basket.go-to-checkout")}
    </Button>
  );

  return (
    <Protected homePath={homePath}>
      <PageWithHeaderAndFooter
        title={basketTitle}
        description={getMicroCopy("basket.description")}
        client={contentfulClient}
        homePath={homePath}
        sx={{ backgroundColor: { xs: "white", sm: "grey.50" } }}
      >
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <Typography component="h1" variant="h4">
              {basketTitle}
            </Typography>
            <CheckoutButton sx={{ display: { xs: "none", sm: "flex" } }} />
          </Stack>
          <Card
            variant="outlined"
            sx={{
              borderWidth: { xs: 0, sm: "initial" },
              my: 3,
              p: { xs: 0, sm: 6 }
            }}
          >
            <Stack spacing={2}>
              <Basket items={basket} homePath={homePath} />
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} sx={{ maxWidth: { sm: 322 } }}>
                  <Stack spacing={2} sx={{ mb: 4.5 }}>
                    <Stack
                      color="text.secondary"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography component="p" variant="h5">
                        {getMicroCopy("basket.subtotal")}
                      </Typography>
                      <Typography component="p" variant="h5">
                        {getMicroCopy("general.currency", {
                          value: roundMoney(sumBasket)
                        })}
                      </Typography>
                    </Stack>
                    <Stack
                      color="text.secondary"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography component="p" variant="h5">
                        {getMicroCopy("basket.delivery")}
                      </Typography>
                      <Typography component="p" variant="h5">
                        {DELIVERY_FEE.title}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography component="h3" variant="h4">
                        {getMicroCopy("basket.total")}
                      </Typography>
                      <Typography component="h3" variant="h4">
                        {getMicroCopy("general.currency", {
                          value: roundMoney(sumBasket + DELIVERY_FEE.value)
                        })}
                      </Typography>
                    </Stack>
                    <Box
                      component="hr"
                      sx={{ border: 0.5, borderColor: "grey.300" }}
                    />
                    <Stack spacing={{ xs: 3, sm: 2 }}>
                      <Stack
                        color={
                          isCheckoutAvailable ? "text.secondary" : "error.main"
                        }
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography component="p" variant="h5">
                          {getMicroCopy("basket.available-balance")}
                        </Typography>
                        <Typography component="p" variant="h5">
                          {getMicroCopy("general.currency", {
                            value: roundMoney(balance)
                          })}
                        </Typography>
                      </Stack>
                      {!isCheckoutAvailable && (
                        <Alert
                          severity="error"
                          variant="standard"
                          icon={<ErrorIcon />}
                        >
                          {getMicroCopy("basket.checkout-disabled-explanation")}
                        </Alert>
                      )}
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    {!isCheckoutAvailable && (
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        href={`${homePath}/rewards`}
                      >
                        {getMicroCopy("basket.go-to-catalogue")}
                      </Button>
                    )}
                    <CheckoutButton fullWidth />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
          <Grid container spacing={3} mb={7.5}>
            {isSendByMail && (
              <Grid item xs={12} sm={6}>
                <Alert severity="info" icon={<DeliveryIcon color="primary" />}>
                  <AlertTitle>
                    {getMicroCopy("basket.delivery-info-title")}
                  </AlertTitle>
                  {getMicroCopy("basket.delivery-info-card")}
                </Alert>
              </Grid>
            )}
            {isSendByEmail && (
              <Grid item xs={12} sm={6}>
                <Alert severity="info" icon={<MailIcon color="primary" />}>
                  <AlertTitle>
                    {getMicroCopy("basket.delivery-info-title")}
                  </AlertTitle>
                  {getMicroCopy("basket.delivery-info-ecard")}
                </Alert>
              </Grid>
            )}
            {anyCharity && (
              <Grid item xs={12} sm={6}>
                <Alert severity="info" icon={<CharityIcon color="primary" />}>
                  <AlertTitle>
                    {getMicroCopy("basket.delivery-info-charity-title")}
                  </AlertTitle>
                  {getMicroCopy("basket.delivery-info-charity")}
                </Alert>
              </Grid>
            )}
          </Grid>
        </Container>
      </PageWithHeaderAndFooter>
    </Protected>
  );
}

export const pageQuery = graphql`
  query BasketPageQuery($id: String!) {
    contentfulClient(id: { eq: $id }) {
      ...Client
    }
  }
`;
